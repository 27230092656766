import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/node_modules/@interness/web-core/src/templates/mdx/default.js";
import { Flex, Box } from '@chakra-ui/react';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia from '@interness/theme-default/src/components/HeaderMedia';
import Subnavigation from '../components/Subnavigation';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <HeaderMedia id='produkte' mdxType="HeaderMedia" />
    <Subnavigation title='Produkte' menu={[{
      "displayName": "Fertigung",
      "path": "/fertigung"
    }, {
      "displayName": "Europaletten",
      "path": "/europaletten"
    }, {
      "displayName": "Paletten 1/2/3 weg",
      "path": "/paletten-123-weg"
    }, {
      "displayName": "Sonderpaletten",
      "path": "/sonderpaletten"
    }, {
      "displayName": "Chemiepaletten",
      "path": "/chemiepaletten"
    }, {
      "displayName": "Euro-Gitterboxen",
      "path": "/euro-gitterboxen"
    }, {
      "displayName": "Vermietung / Reparaturen",
      "path": "/vermietung-reparaturen"
    }]} mdxType="Subnavigation" />
    <hr></hr>
    <Wrapper mdxType="Wrapper">
  <Heading subtitle='Know-How und moderne Technik sichern Qualität' mdxType="Heading">Fertigung</Heading>
  <Flex flexWrap='wrap' textAlign='justify' mdxType="Flex">
    <Box width={['100%', '100%', '33%', '33%']} mdxType="Box">
      <h3>Disposition</h3>
      <p>Von der Disposition des Vormaterials über die Fertigung bis hin zur Endkontrolle und der anschließenden
        Logistik sorgen engagierte Mitarbeiter für einen reibungslosen Ablauf.</p>
    </Box>
    <Box width={['100%', '100%', '33%', '33%']} px={6} mdxType="Box">
      <h3>Kombination</h3>
      <p>Die Kombination aus langjähriger Erfahrung, modernen Fertigungsanlagen sowie absolut hochwertigen Materialien
        sind der Garant für optimale Qualität unserer Holzpaletten.</p>
    </Box>
    <Box width={['100%', '100%', '33%', '33%']} mdxType="Box">
      <h3>Logistik</h3>
      <p>Effiziente Logistikstrukturen, und modernste Fahrzeuge und eigene Wechselbrücken, sowie qualifiziertes Personal
        ermöglichen es uns, auf alle Kundenwünsche schnell und flexibel zu reagieren.</p>
    </Box>
  </Flex>
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      